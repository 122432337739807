import React, { Suspense, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Loggedincontext } from './Loggedincontext.js';
import { Routedispatcherprovider } from './Routedispatcher';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './components/Website/Generalfiles/CSS_GENERAL/bootstrap.css';
import './trans.css';
import { Contexthandlerscontext_provider } from './Contexthandlerscontext';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import logopng from './components/Website/Generalfiles/images/logopng.png';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const Websiterouter = React.lazy(() => import('./components/Website/Websiterouter'));
const Login = React.lazy(() => import('./components/Website/Login/Login'));
const Clientapproval = React.lazy(() => import('./components/Website/Clientapproval/Clientapproval'));
const ClientapprovalKSA = React.lazy(() => import('./components/Website/Clientapproval/ClientapprovalKSA'));
const Contract = React.lazy(() => import('./components/Website/Clientapproval/Contract'));
const JSONFile = React.lazy(() => import('./components/Website/UploadJson/JSONFile'));
const BuildLogs = React.lazy(() => import('./components/Website/UploadJson/BuildLogs'));

const App = (props) => {
    let history = useHistory();
    const { fetchuseauthorizationQueryContext, setuserloggedinfobjcontext, isloggedincontext, setisloggedincontext } = React.useContext(Loggedincontext);

    return (
        <Contexthandlerscontext_provider>
            {fetchuseauthorizationQueryContext.isFetching && (
                <div style={{ height: '100vh', backgroundColor: 'var(--white)' }} class="row w-100 allcentered m-0">
                    <div class="col-lg-12 p-0">
                        <div class="row m-0 w-100">
                            {' '}
                            <div class="col-lg-12 p-0 d-flex allcentered">
                                <img style={{ objectFit: 'contain', widht: '10vh', height: '10vh' }} src={logopng} />
                            </div>
                            <div class="col-lg-12 p-0 d-flex allcentered">
                                <CircularProgress color="var(--primary)" width="60px" height="60px" duration="1s" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {fetchuseauthorizationQueryContext?.isSuccess && !fetchuseauthorizationQueryContext?.isFetching && (
                <Router>
                    <Routedispatcherprovider>
                        <div style={{ height: '100%', width: '100%', background: 'var(--white)' }}>
                            <Suspense
                                fallback={
                                    <div style={{ height: '100vh', backgroundColor: 'var(--white)' }} class="row w-100 allcentered m-0">
                                        <div class="col-lg-12 p-0">
                                            <div class="row m-0 w-100">
                                                {' '}
                                                <div class="col-lg-12 p-0 d-flex allcentered">
                                                    <img style={{ objectFit: 'contain', widht: '10vh', height: '10vh' }} src={logopng} />
                                                </div>
                                                <div class="col-lg-12 p-0 d-flex allcentered">
                                                    <CircularProgress color="var(--primary)" width="60px" height="60px" duration="1s" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            >
                                <Route
                                    exact
                                    path="/"
                                    render={(props) => {
                                        return <Redirect to={fetchuseauthorizationQueryContext?.data?.data?.loggedin ? '/home' : `/login`} />;
                                    }}
                                />
                                {fetchuseauthorizationQueryContext?.data?.data?.loggedin && <Websiterouter />}
                                {!fetchuseauthorizationQueryContext?.data?.data?.loggedin && (
                                    <>
                                        <Route exact path="/login" component={Login} />
                                        <Route exact path="/clientapproval/:typeparam/:dealidparam" component={Clientapproval} />
                                        <Route exact path="/clientapprovalksa/:typeparam/:dealidparam" component={ClientapprovalKSA} />
                                        <Route exact path="/jsonfile" component={JSONFile} />
                                        <Route exact path="/contract" component={Contract} />

                                        <Route exact path="/buildlogs" component={BuildLogs} />
                                    </>
                                )}

                                {/* {!fetchuseauthorizationQueryContext?.data?.data?.loggedin && <Login />} */}
                            </Suspense>
                        </div>
                    </Routedispatcherprovider>
                </Router>
            )}

            <NotificationContainer />
            <ReactQueryDevtools initialIsOpen />
        </Contexthandlerscontext_provider>
    );
};

export default App;
